import { useState } from 'react';

import { DialogContext, BreadcrumbsContext } from './contexts';
import AuthProvider from './authProvider';

import './styles/variables.css';
import './styles/styles.css';

import Main from './main';

export default function App() {
		
	const [dialog, setDialog] = useState();
	
	const [breadcrumbs, setBreadcrumbs] = useState([
		{
			path: '/dashboard',
			title: 'Icelandic with Max',
			mobileStyle: 'hidden'
		}
	]);
	
  return (
		<AuthProvider>
			<DialogContext.Provider value={{dialog, setDialog}}>
	  		<BreadcrumbsContext.Provider value={{breadcrumbs, setBreadcrumbs}}>
					<Main />
				</BreadcrumbsContext.Provider>
			</DialogContext.Provider>
		</AuthProvider>
  );
}