import { useState, useEffect } from 'react';
import { stringify } from 'qs';

import { AuthContext } from './contexts';
import { getToken } from './helpers';

export default function AuthProvider({ 
		children 
	}) {
	
	const [userData, setUserData] = useState();
	const [purchases, setPurchases] = useState();
	const [loading, setLoading] = useState(false);
	
	const authToken = getToken();
	
	async function fetchLoggedInUser({ token }) {		
		setLoading(true);
		try {
			const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users/me`, {
				headers: { 
					'Strapi-Response-Format': 'v4',
					Authorization: `${process.env.REACT_APP_STRAPI_BEARER} ${authToken}` 
				},
			});
			const data = await response.json();	
			if(data?.error) {
				throw data?.error;
			} else {				
				if(data) {
					try {
						const purchaseQ = stringify({
							filters: {
								user: {
									$eq: data.id
								}
							},							
							populate: ['product']
						});
						const purchaseResponse = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/purchases?${purchaseQ}`, {
							headers: {
								'Strapi-Response-Format': 'v4',
								Authorization: `Bearer ${authToken}`
							}
						});
						const purchaseData = await purchaseResponse.json();	
						setUserData(data);	
						setPurchases(purchaseData.data);
						localStorage.setItem('lang', data.lang);
						localStorage.setItem('appearance', data.appearance);
					} catch(error) {
						console.error(error);
					}
				}			
			}			
		} catch(error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}
	
	function handleUser({ user }) {
		setUserData(user);
	}

	useEffect(() => {
		if(authToken) {
			fetchLoggedInUser(authToken);
		}
	}, [authToken]);
	
	return(
		<AuthContext.Provider
			value={{ purchases: purchases, user: userData, setUser: handleUser, loading }}>
			{children}
		</AuthContext.Provider>
	)
	
}		