import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAuthContext, DialogContext } from '../contexts';
import { getToken } from '../helpers';

import { Button } from '../components/atoms';

export default function ReportProblem({ visible }) {
	
	const { t } = useTranslation();	
	
	const authToken = getToken();
	const user = useAuthContext();
	
	const [loading, setLoading] = useState(false);
	const [screen, setScreen] = useState(1);
	const [problemType, setProblemType] = useState('other');
	const [prettyProblemType, setPrettyProblemType] = useState();
	
	const { register, handleSubmit, reset, setError, setFocus, formState, formState: { isSubmitSuccessful, errors } } = useForm();
	
	const dialog = useContext(DialogContext);
	
	function handleCloseDialog() {
		dialog.setDialog();
		setScreen(1);
	}
	
	function handleSetProblem(value) {
		setProblemType(value);
		switch(value) {
			case 'content':
				setPrettyProblemType(t('reportProblem.content'));
				break;
			case 'bug':
				setPrettyProblemType(t('reportProblem.bug'));
				break;
			case 'other':
				setPrettyProblemType(t('reportProblem.other'));
				break;
		}
		setScreen(2);
	}
	
	async function handleReportProblem(formData) {
		setLoading(true);
		try {
			const data = {
				user: user.user.id,
				type: formData.problemType,
				description: formData.description,
				href: window.location.href,
				userAgent: navigator.userAgent,
				uiLang: user.user.lang,
				uiAppearance: user.user.appearance,
				windowWidth: window.innerWidth,
				windowHeight: window.innerHeight
			}
			const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/feedbacks`, {
				method: 'POST',
				headers: {
					'Strapi-Response-Format': 'v4',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${authToken}`
				},
				body: JSON.stringify({ data }),
			});
		
			const responseData = await response.json();
			if (responseData?.error) {
				throw responseData?.error;
			} else {
				setLoading(false);
				setScreen(3);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}
	
	useEffect(() => {
		if(formState.isSubmitSuccessful) {
			reset();
		}
	}, [formState, reset]);
	
	return(
		<div className={`dialog report-problem-dialog${dialog.dialog === 'report-problem' ? (' visible') : ('')}`}>
			<div className="report-problem-dialog-header">
				<h2>{t('reportProblem.heading')}</h2>
				<Button 
					onClick={handleCloseDialog}
					role="secondary icon"
					icon="close" 
				/>
			</div>
			{screen === 1 && (
				<div className="form-row">
					<strong>{t('reportProblem.whatIs')}</strong>
					<ul className="wizard-options">
						<li onClick={() => handleSetProblem('content')}>
							<div className="wizard-option-label">
								<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M10 0L14 4H10V0ZM9 4C9 4.5625 9.4375 5 10 5H14V14.5C14 15.3438 13.3125 16 12.5 16H3.5C2.65625 16 2 15.3438 2 14.5V1.5C2 0.6875 2.65625 0 3.5 0H9V4ZM10.5 13C10.75 13 11 12.7812 11 12.5C11 12.25 10.75 12 10.5 12H5.5C5.21875 12 5 12.25 5 12.5C5 12.7812 5.21875 13 5.5 13H10.5ZM10.5 11C10.75 11 11 10.7812 11 10.5C11 10.25 10.75 10 10.5 10H5.5C5.21875 10 5 10.25 5 10.5C5 10.7812 5.21875 11 5.5 11H10.5ZM11 8.5C11 8.25 10.75 8 10.5 8H5.5C5.21875 8 5 8.25 5 8.5C5 8.78125 5.21875 9 5.5 9H10.5C10.75 9 11 8.78125 11 8.5Z" fill="#1C1E21"/>
								</svg>
								{t('reportProblem.content')}
							</div>
							<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2 14.5C1.71875 14.5 1.46875 14.4062 1.28125 14.2188C0.875 13.8438 0.875 13.1875 1.28125 12.8125L6.5625 7.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L8.6875 6.8125C9.09375 7.1875 9.09375 7.84375 8.6875 8.21875L2.6875 14.2188C2.5 14.4062 2.25 14.5 2 14.5Z" fill="white"/>
							</svg>					
						</li>
						<li onClick={() => handleSetProblem('bug')}>
							<div className="wizard-option-label">
								<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12 3V3.125C12 3.625 11.5938 4 11.0938 4H6.875C6.375 4 5.96875 3.625 5.96875 3.125V3C5.96875 1.34375 7.3125 0 8.96875 0C10.6562 0 12 1.34375 12 3ZM2.28125 3.3125C2.65625 2.90625 3.3125 2.90625 3.6875 3.3125L5.6875 5.3125C5.71875 5.3125 5.71875 5.34375 5.75 5.375C6.1875 5.15625 6.6875 5 7.21875 5H10.75C11.2812 5 11.7812 5.15625 12.2188 5.375C12.25 5.34375 12.25 5.3125 12.2812 5.3125L14.2812 3.3125C14.6562 2.90625 15.3125 2.90625 15.6875 3.3125C16.0938 3.6875 16.0938 4.34375 15.6875 4.71875L13.6875 6.71875C13.6562 6.75 13.6562 6.75 13.625 6.78125C13.8125 7.15625 13.9375 7.5625 13.9688 8H16C16.5312 8 17 8.46875 17 9C17 9.5625 16.5312 10 16 10H14C14 10.7812 13.8125 11.5 13.5 12.1562C13.5625 12.1875 13.625 12.25 13.6875 12.3125L15.6875 14.3125C16.0938 14.6875 16.0938 15.3438 15.6875 15.7188C15.3125 16.125 14.6562 16.125 14.2812 15.7188L12.3125 13.75C11.5312 14.4375 10.5625 14.875 9.5 15V7.5C9.5 7.25 9.25 7 8.96875 7C8.71875 7 8.46875 7.25 8.46875 7.5V15C7.40625 14.875 6.4375 14.4375 5.65625 13.75L3.6875 15.7188C3.3125 16.125 2.65625 16.125 2.28125 15.7188C1.875 15.3438 1.875 14.6875 2.28125 14.3125L4.28125 12.3125C4.34375 12.25 4.40625 12.1875 4.46875 12.1562C4.15625 11.5 4 10.7812 4 10H2C1.4375 10 1 9.5625 1 9C1 8.46875 1.4375 8 2 8H4C4.03125 7.5625 4.15625 7.15625 4.34375 6.78125C4.3125 6.75 4.3125 6.75 4.28125 6.71875L2.28125 4.71875C1.875 4.34375 1.875 3.6875 2.28125 3.3125Z" fill="#1C1E21"/>
								</svg>
								{t('reportProblem.bug')}
							</div>
							<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2 14.5C1.71875 14.5 1.46875 14.4062 1.28125 14.2188C0.875 13.8438 0.875 13.1875 1.28125 12.8125L6.5625 7.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L8.6875 6.8125C9.09375 7.1875 9.09375 7.84375 8.6875 8.21875L2.6875 14.2188C2.5 14.4062 2.25 14.5 2 14.5Z" fill="white"/>
							</svg>					
						</li>
						<li onClick={() => handleSetProblem('other')}>
							<div className="wizard-option-label">
								<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M4.75 8C4.75 8.96875 3.9375 9.75 3 9.75C2.03125 9.75 1.25 8.96875 1.25 8C1.25 7.0625 2.03125 6.25 3 6.25C3.9375 6.25 4.75 7.0625 4.75 8ZM9.75 8C9.75 8.96875 8.9375 9.75 8 9.75C7.03125 9.75 6.25 8.96875 6.25 8C6.25 7.0625 7.03125 6.25 8 6.25C8.9375 6.25 9.75 7.0625 9.75 8ZM11.25 8C11.25 7.0625 12.0312 6.25 13 6.25C13.9375 6.25 14.75 7.0625 14.75 8C14.75 8.96875 13.9375 9.75 13 9.75C12.0312 9.75 11.25 8.96875 11.25 8Z" fill="#1C1E21"/>
								</svg>						
								{t('reportProblem.other')}
							</div>
							<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2 14.5C1.71875 14.5 1.46875 14.4062 1.28125 14.2188C0.875 13.8438 0.875 13.1875 1.28125 12.8125L6.5625 7.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L8.6875 6.8125C9.09375 7.1875 9.09375 7.84375 8.6875 8.21875L2.6875 14.2188C2.5 14.4062 2.25 14.5 2 14.5Z" fill="white"/>
							</svg>					
						</li>
					</ul>
				</div>
			)}
			{screen === 2 && (
				<div className={`report-problem-screen${screen === 2 ? (' visible') : ('')}`}>
					<form onSubmit={handleSubmit(handleReportProblem)}>
						<div className="form-row">
							<input
								name="problem-type"
								id="problem-type"
								type="hidden"
								value={problemType} 
								{...register('problemType')}
							/>
							<span><strong>{t('reportProblem.problem')}</strong> {prettyProblemType}</span>
						</div>
						<div className="form-row">
							<label>{t('reportProblem.description')}</label>
							<textarea 
								name="problem-description"
								id="problem-description" 
								autoFocus								
								{...register('description', { required: true })}
								aria-invalid={errors.description ? "true" : "false"}
							></textarea>
							{loading ? (
								<button disabled>{t('reportProblem.buttonSubmitting')}</button>
							) : (
								<button type="submit">{t('reportProblem.buttonReportProblem')}</button>
							)}	
						</div>
					</form>
				</div>
			)}
			{screen === 3 && (
				<div className={`report-problem-screen${screen === 3 ? (' visible') : ('')}`}>
					<div className="form-row">
						<p>{t('reportProblem.thankYou')}</p>
					</div>
				</div>
			)}
		</div>
	)
}