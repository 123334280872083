/* Authentication */

export function getToken() {
	return localStorage.getItem(process.env.REACT_APP_STRAPI_AUTH_TOKEN);
}

export function setToken(token) {
	if (token) {
		localStorage.setItem(process.env.REACT_APP_STRAPI_AUTH_TOKEN, token);
	}
}

export function removeToken() {
	localStorage.removeItem(process.env.REACT_APP_STRAPI_AUTH_TOKEN);
}


/* Date calculations */

export function calculateTrialDays(expiryDate) {	
	const today = new Date().getTime();
	expiryDate = Date.parse(expiryDate);			
	const difference = expiryDate - today;
	return  Math.round(difference / (1000 * 3600 * 24))
}

export function formatDate(string, lang) {
	return new Date(string).toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric'});
}


/* Page list */

export function parsePageList(data) {
	var pageObject = {
		pageContext: null,
		currentPage: null,
		lastPage: null,
		nextPage: null
	}
	var pageList = data.attributes.lesson.data.attributes.lessonPages.data
	var currentPageIndex = pageList.findIndex(({ id }) => id === data.id);
	pageObject.currentPage = pageList.slice(currentPageIndex, currentPageIndex+1);
	pageObject.lastPage = pageList.slice(currentPageIndex-1, currentPageIndex);
	pageObject.nextPage = pageList.slice(currentPageIndex+1, currentPageIndex+2);
	var pageContext = {
		pageCount: pageList.length,
		currentPagePos: currentPageIndex+1,
		progressBarwidth: ((currentPageIndex+1) / pageList.length)*100+"%",
	};
	pageObject.pageContext = pageContext;
	return(pageObject);
}


/* Course progress */

export function initialiseProgressJSON(lessons) {
	var progressJSON = [];
	var lessonPageJSON = [];
	lessons.map((thisLesson, i) => {
		if(thisLesson.attributes.lessonPages.data.length>0) {
			lessonPageJSON = [];
			thisLesson.attributes.lessonPages.data.map((thisLessonPage, j) => {
				lessonPageJSON.push({
					documentId: thisLessonPage.documentId,
					progress: 0
				})
			});
			progressJSON.push({
				documentId: thisLesson.documentId,
				progress: 0,
				lastViewedPage: null,
				lessonPages: lessonPageJSON
			});
		}		
	});
	return progressJSON;
}

export async function initialiseProgress(newProgressData) {
		
	const authToken = getToken();
	
	await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/course-progresses`, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${authToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(newProgressData)
	})
	.then((response) => response.json())
	.then((data) => { 
		const updatedPurchaseData = {
			data: {
				progress: data.data.id,
			}
		}
	})
	.catch((error) => {
		console.log(error);
	});
	
}


/* Misc */

export function shuffle(array) {
	let currentIndex = array.length;
	while (currentIndex != 0) {
		let randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex], array[currentIndex]];
	}
	return array;
}

export function arrayEquals(a, b) {
	return Array.isArray(a) &&
			Array.isArray(b) &&
			a.length === b.length &&
			a.every((val, index) => val === b[index]);
}