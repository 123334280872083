import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
	.use(initReactI18next)
	.init({
		fallbackLng: localStorage.getItem('lang') || 'en-gb',
		interpolation: {
			escapeValue: false,
		},
		resources: {
			en: {
				translation: {
					navMenu: { 
						courses: 'Courses', 
						wordList: 'Wordlist',
						reportProblem: 'Report a problem',
						interfaceLanguage: {
							interfaceLanguage: 'Interface language',
							icelandic: 'Icelandic',
							english: 'English',
							courseMaterial: 'Course material only available in English'
						},
						appearance: { 
							appearance: 'Appearance',
							auto: 'Auto',
							light: 'Light',
							dark: 'Dark'
						},
						contentCaching: {
							contentCaching: 'Content caching',
							on: 'On',
							off: 'Off'
						}
					},
					userMenu: { 
						account: 'Account', 
					},
					errors: {
						pageLoadError: 'This page could not be loaded.',
					},
					footer: {
						privacyPolicy: 'Privacy policy',
						gramRef: 'Icelandic Grammar Reference',
						instagram: 'Instagram',
						youTube: 'YouTube',
						contact: 'Get in touch'
					},
					dashboard: { 
						courses: 'Courses', 
						buttonStart: 'Start course',
						buttonContinue: 'Continue',
						buttonPurchase: 'Purchase',
						notStarted: 'Not started',
						inProgress: 'In progress',
						complete: 'Complete',
						errorLoading: 'Your courses could not be loaded.',
						noPurchases: 'You haven’t purchased any courses.',
						buttonPurchaseCourse: 'Purchase course'
					},
					course: {
						buttonStartLesson: 'Start lesson',	
						buttonContinueLesson: 'Continue lesson',				
						buttonReviewLesson: 'Review lesson'
					},
					lessonPage: {
						buttonBackToOverview: 'Back to lesson overview',
						buttonFinishLesson: 'Finish lesson'
					},
					trial: {
						trialExpired: 'Trial expired',
						trialLeft: ' days left',
						expiredDialog: {
							heading: 'Your 7-day free trial has expired',
							explanation: 'To continue learning, you need to purchase a course.',
							buttonPurchaseCourse: 'Purchase course'
						}
					},
					reportProblem: {
						heading: 'Report a problem on this page',
						whatIs: 'What’s the problem?',
						content: 'Mistake in course content',
						bug: 'Feature not working properly',
						other: 'Something else',
						problem: 'Problem:',
						description: 'Describe the problem in as much detail as you can.',
						buttonReportProblem: 'Report problem',
						buttonSubmitting: 'Submitting…',
						thankYou: '🙏 Thanks for telling us about the problem. We might contact you via email if we need more information.'
					},
					account: {
						account: 'Account',
						personalDetails: {
							personalDetails: 'Personal details',
							firstName: 'First name',
							email: 'Email address',
							gender: {
								gender: 'Gender',
								male: 'Male',
								female: 'Female',
								nonbinary: 'Non-binary',
								explanation: 'This is only used to determine which adjective form to address you with in Icelandic'
							},
							saveChanges: 'Save changes',
							saving: 'Saving…',
							saved: 'Your personal details have been updated 🙌',
						},	
						password: {
							password: 'Password',
							buttonChangePassword: 'Change password…'
						},
						purchaseHistory: {
							purchaseHistory: 'Purchase history',
							course: 'Course',
							purchaseDate: 'Purchase date',
							downloadInvoice: 'Download invoice',
							errors: {
								noPurchases: 'No purchases found ☹️'
							}
						},
						accountManagement: {
							accountManagement: 'Account management',
							logOut: 'Log out',
							deleteAccount: 'Delete account…',
							explanation: 'Deleting your account is permanent. You will lose all your progress and access to your purchased courses. You will have to repurchase your courses if you create a new account later on.'
						},
						changePassword: {
							changePassword: 'Change password',
							existingPassword: 'Existing password',
							newPassword: 'New password',
							confirmNewPassword: 'Confirm new password',
							buttonChangePassword: 'Change password',
							buttonCancel: 'Cancel',
							passwordChanged: 'Password changed 🙌',
							errors: {
								noPassword: 'You must enter your existing password 🔐',
								incorrectPassword: 'Existing password incorrect.',
								noMatch: 'New passwords don’t match.'
							}
						},
						deleteAccount: {
							deleteAccount: 'Delete account',
							areYouSure: '⚠️ Are you sure you want to delete your account? Deleting your account is permanent.',
							deleteWarning: 'You will lose all your progress and access to your purchased courses. You will have to repurchase your courses if you create a new account later on.',
							buttonContinueToDelete: 'Continue to delete account',
							buttonCancel: 'Cancel',
							deleteConfirm: 'To confirm that you want to delete your account, enter your password.',
							buttonDelete: 'Delete account permanently',
							verifyingPassword: 'Verifying password…',
							errors: {								
								noPassword: 'You must enter your password 🔐',
								incorrectPassword: 'Incorrect password.'
							}
						},
						advanced: {
							advanced: 'Advanced',							
							buttonClearExerciseAnswers: 'Delete exercise answers',
							buttonClearCache: 'Clear cache'
						}
					},
				},
			},
			is: {				
				translation: {
					navMenu: { 
						courses: 'Námskeið', 
						wordList: 'Orðalisti',
						reportProblem: 'Tilkynna vandamál',
						interfaceLanguage: {
							interfaceLanguage: 'Viðmótstungumál',
							icelandic: 'Íslenska',
							english: 'Enska',
							courseMaterial: 'Námsefni er eingöngu á ensku'
						},
						appearance: { 
							appearance: 'Útlit',
							auto: 'Sjálfstillt',
							light: 'Ljóst',
							dark: 'Dökkt'
						},
						contentCaching: {
							contentCaching: 'Skyndiminni kennsluefnis',
							on: 'Kveikt',
							off: 'Slökkt'
						}
					},
					userMenu: { 
						account: 'Aðgangur', 
					},
					errors: {
						pageLoadError: 'Ekki tókst að sækja þessa síðu.',
					},
					footer: {
						privacyPolicy: 'Persónuverndarstefna',
						gramRef: 'Icelandic Grammar Reference',
						instagram: 'Instagram',
						youTube: 'YouTube',
						contact: 'Hafa samband'
					},
					dashboard: { 
						courses: 'Námskeið', 
						buttonStart: 'Hefja námskeið',
						buttonContinue: 'Halda áfram',
						buttonPurchase: 'Kaupa',
						notStarted: 'Ekki hafið',
						inProgress: 'Hafið',
						complete: 'Lokið',
						errorLoading: 'Villa kom upp við að sækja námskeið.',
						noPurchases: 'Þú hefur ekki keypt námskeið.',
						buttonPurchaseCourse: 'Kaupa námskeið'
					},
					course: {
						buttonStartLesson: 'Hefja kennslutíma',
						buttonContinueLesson: 'Halda kennslutíma áfram',							
						buttonReviewLesson: 'Rifja upp kennslutíma'
					},
					lessonPage: {
						buttonBackToOverview: 'Aftur í yfirlit kennslutímans',
						buttonFinishLesson: 'Ljúka kennslutíma'
					},
					trial: {
						trialExpired: 'Prufutími útrunninn',
						trialLeft: ' dagar eftir',
						expiredDialog: {
							heading: '7 daga prufutíminn er útrunninn',
							explanation: 'Ef þú vilt halda áfram að læra þarft þú að kaupa námskeið.',
							buttonPurchaseCourse: 'Kaupa námskeið'
						}
					},
					reportProblem: {
						heading: 'Tilkynna vandamál',
						whatIs: 'Hvert er vandamálið?',
						content: 'Villa í námsefni',
						bug: 'Óvænt hegðun eða virkni',
						other: 'Eitthvað annað',
						problem: 'Vandamál:',
						description: 'Lýsti vandamálinu eins ítarlega og þú getur.',
						buttonReportProblem: 'Tilkynna vandamál',
						buttonSubmitting: 'Sendir inn…',
						thankYou: '🙏 Takk fyrir að láta okkur vita af vandamálinu. Við kunnum að hafa samband við þig í tölvupósti ef við þurfum á meiri upplýsingum að halda.'
					},
					account: {
						account: 'Aðgangur',
						personalDetails: {
							personalDetails: 'Persónuupplýsingar',
							firstName: 'Fornafn',
							email: 'Netfang',
							gender: {
								gender: 'Kyn',
								male: 'Karl',
								female: 'Kona',
								nonbinary: 'Kynsegin',
								explanation: 'Þetta er notað eingöngu til að ávarpa þig í réttu málfræðilegu kyni á íslensku'
							},
							saveChanges: 'Vista breytingar',
							saving: 'Vistar…',
							saved: 'Persónuupplýsingar þínar hafa verið uppfærðar 🙌'
						},	
						password: {
							password: 'Lykilorð',
							buttonChangePassword: 'Breyta lykilorði…'
						},
						purchaseHistory: {
							purchaseHistory: 'Keypt námskeið',
							course: 'Námskeið',
							purchaseDate: 'Keypt',
							downloadInvoice: 'Sækja reikning',
							errors: {
								noPurchases: 'Þú hefur ekki keypt námskeið ☹️'
							}
						},
						accountManagement: {
							accountManagement: 'Stjórna aðgangi',
							logOut: 'Útskrá',
							deleteAccount: 'Eyða aðgangi',
							explanation: 'Að eyða aðganginum þínum er óafturkallanleg aðgerð. Þú missir öll árangursgögn og aðgang að keyptum námskeiðum. Þú verður að borga námskeiðsgjöldin aftur ef þú stofnar nýjan aðgang seinna meir.'
						},
						changePassword: {
							changePassword: 'Breyta lykilorði',
							existingPassword: 'Núverandi lykilorð',
							newPassword: 'Nýtt lykilorð',
							confirmNewPassword: 'Staðfesta nýtt lykilorð',
							buttonChangePassword: 'Breyta lykilorði',
							buttonCancel: 'Hætta við',
							passwordChanged: 'Lykilorði breytt 🙌',
							errors: {
								noPassword: 'Þú verður að slá inn núverandi lykilorðið þitt 🔐',
								incorrectPassword: 'Vitlaust núverandi lykilorð.',
								noMatch: 'Nýju lykilorðin stemma ekki.'
							}
						},
						deleteAccount: {
							deleteAccount: 'Eyða aðgangi',
							areYouSure: '⚠️ Ertu viss um að þú viljir eyða aðganginum þínum? Þetta er óafturkallanleg aðgerð.',
							deleteWarning: 'Þú missir öll árangursgögn og aðgang að keyptum námskeiðum. Þú verður að borga námskeiðsgjöldin aftur ef þú stofnar nýjan aðgang seinna meir.',
							buttonContinueToDelete: 'Halda áfram og eyða',
							buttonCancel: 'Hætta við',
							deleteConfirm: 'Til að staðfesta að þú viljir eyða aðganginum þínum, sláðu inn lykilorðið þitt.',
							buttonDelete: 'Eyða aðgangi varanlega',
							verifyingPassword: 'Athugar lykilorð…',
							errors: {
								noPassword: 'Þú verður að slá inn lykilorðið þitt 🔐',
								incorrectPassword: 'Vitlaust lykilorð.'
							}							
						},
						advanced: {
							advanced: 'Ítarlegt',							
							buttonClearExerciseAnswers: 'Eyða svörum við æfingum',
							buttonClearCache: 'Hreinsa skyndiminni'
						}
					},
				},
			},
		},
	})

export default i18n;