export default function NotFound() {
	return(
		<>
			<div className="container no-x-padding">
				<div className="blurb x-padding">
					<h1>Úbbs! Page not found</h1>
					<p>There’s nothing here.</p>
				</div>				
			</div>
		</>
	)	
}
