import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect } from 'react';

import { BrowserRouter, useLocation } from 'react-router-dom';

import TagManager from 'react-gtm-module';

import i18n from './i18n';

import './index.css';
import App from './app';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const env = process.env.NODE_ENV;

const tagManagerArgs = {
  gtmId: "GTM-WKZ95GFF"
};

if(env === "production") {
  TagManager.initialize(tagManagerArgs);
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}

root.render(
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
