import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '../../contexts';
import { getToken, calculateTrialDays } from '../../helpers';

import Breadcrumbs from './breadcrumbs';
import { DialogContext } from '../../contexts';

import { ToggleButtons } from '../atoms';

function TrialBadge() {	
	
	const { t } = useTranslation();	
	const user = useAuthContext();	
	
	if(user.user) {
		if(user.user.trialExpiry) {			
			const trialDays = calculateTrialDays(user.user.trialExpiry);			
			if(trialDays > 0) {
				return(							
					<div className="trial-badge">
						{trialDays} {t('trial.trialLeft')}
					</div>
				)
			} else {
				return(							
					<div className="trial-badge expired">
						{t('trial.trialExpired')}
					</div>
				)
			}			
		}
	}			
	
}

function NavDropdown({
	visibleMenu,
	setVisibleMenu,
	parent,
	handleDropdownToggle
}) {
	
	const { t } = useTranslation();	
	const ref = useRef(null);
	
	const dialog = useContext(DialogContext);
	
	function handleReportProblem() {
		handleDropdownToggle();
		dialog.setDialog('report-problem');
	}
	
	return(
		<>
			<div className={`dropdown nav-dropdown${visibleMenu === 'nav' ? (' visible') : ('')}`} ref={ref}>
				<ul>
					<li>
						<Link to="/dashboard" onClick={handleDropdownToggle}>
							<div className="icon">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M20.9688 12C20.9688 12.5625 20.5 13 19.9688 13H18.9688L19 18C19 18.0938 19 18.1875 19 18.25V18.75C19 19.4688 18.4375 20 17.75 20H17.25C17.1875 20 17.1562 20 17.125 20C17.0938 20 17.0312 20 17 20H15.25C14.5312 20 14 19.4688 14 18.75V16C14 15.4688 13.5312 15 13 15H11C10.4375 15 10 15.4688 10 16V18.75C10 19.4688 9.4375 20 8.75 20H7C6.9375 20 6.90625 20 6.84375 20C6.8125 20 6.78125 20 6.75 20H6.25C5.53125 20 5 19.4688 5 18.75V15.25C5 15.25 5 15.2188 5 15.1875V13H4C3.4375 13 3 12.5625 3 12C3 11.7188 3.09375 11.4688 3.3125 11.25L11.3125 4.28125C11.5312 4.0625 11.7812 4 12 4C12.2188 4 12.4688 4.09375 12.6562 4.25L20.625 11.25C20.875 11.4688 21 11.7188 20.9688 12Z" fill="#0B00D1"/>
								</svg>								
							</div>
							{t('navMenu.courses')}
						</Link>
					</li>
{/* 					<li>
						<Link to="/wordlist" onClick={handleDropdownToggle}>
							<div className="icon">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M19 14.5C19 14.9688 18.7812 15.3438 18.5 15.6562V18.1875C18.7812 18.375 19 18.6875 19 19.0312C19 19.5625 18.5312 20 18 20H8C6.3125 20 5 18.6875 5 17V7C5 5.34375 6.3125 4 8 4H17.5C18.3125 4 19 4.6875 19 5.5V14.5ZM9.46875 8C9.21875 8 9 8.25 9 8.5C9 8.78125 9.21875 9 9.46875 9H15.5C15.75 9 16 8.78125 16 8.5C16 8.25 15.75 8 15.4688 8H9.46875ZM9.46875 10C9.21875 10 9 10.25 9 10.5C9 10.7812 9.21875 11 9.46875 11H15.5C15.75 11 16 10.7812 16 10.5C16 10.25 15.75 10 15.4688 10H9.46875ZM17 18V16H8C7.4375 16 7 16.4688 7 17C7 17.5625 7.4375 18 8 18H17Z" fill="#0B00D1"/>
								</svg>							
							</div>
							{t('navMenu.wordList')}
						</Link>
					</li> */}
				</ul>
				<ul>
					<li className="linkless" onClick={handleReportProblem}>
							<div className="icon">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M19.8125 17.0312C20.3125 17.9062 19.6875 19 18.6562 19H5.3125C4.28125 19 3.65625 17.9062 4.15625 17.0312L10.8438 5.65625C11.3438 4.78125 12.625 4.78125 13.1562 5.65625L19.8125 17.0312ZM11.25 9.25V13.25C11.25 13.6875 11.625 14 12 14C12.4062 14 12.75 13.6875 12.75 13.25V9.25C12.75 8.84375 12.4062 8.5 12 8.5C11.5625 8.5 11.25 8.84375 11.25 9.25ZM12 17C12.5312 17 12.9688 16.5625 12.9688 16.0312C12.9688 15.5 12.5312 15.0625 12 15.0625C11.4375 15.0625 11 15.5 11 16.0312C11 16.5625 11.4375 17 12 17Z" fill="#0B00D1"/>
								</svg>								
							</div>
							{t('navMenu.reportProblem')}
					</li>
				</ul>
			</div>
		</>
	)
	
}

function UserDropdown({
	visibleMenu,
	setVisibleMenu,
	parent,
	handleDropdownToggle
}) {
	
	const { t, i18n } = useTranslation();	
	const ref = useRef(null);
	
	const user = useAuthContext();
	
	const authToken = getToken();
	const { setUser } = useAuthContext();
	
	const toggleButtonLangs = [{
			title: t('navMenu.interfaceLanguage.icelandic'),
			value: 'is'
		},
		{
			title: t('navMenu.interfaceLanguage.english'),
			value: 'en-gb'
		}
	];
	
	const toggleButtonAppearance = [{
			title: t('navMenu.appearance.auto'),
			value: 'auto'
		},
		{
			title: t('navMenu.appearance.light'),
			value: 'light'
		},
		{
			title: t('navMenu.appearance.dark'),
			value: 'dark'
		}
	];
	
	const toggleButtonCaching = [{
			title: t('navMenu.contentCaching.on'),
			value: false
		},
		{
			title: t('navMenu.contentCaching.off'),
			value: true
		}
	];
	
	function handleSwitchLanguage(lang) {
		i18n.changeLanguage(lang);
		handleDropdownToggle();
		handleUpdateUserPreference({ lang: lang });
		localStorage.setItem('lang', lang);
	}
	
	function handleSwitchAppearance(mode) {
		document.body.classList.remove('light');
		document.body.classList.remove('dark');
		if(mode === 'dark') {
			document.body.classList.add('dark');
		} if(mode === 'light') {
			document.body.classList.add('light');
		}
		handleDropdownToggle();
		handleUpdateUserPreference({ appearance: mode });
		localStorage.setItem('appearance', mode);
	}
	
	function handleSwitchCaching(disableCaching) {
		handleDropdownToggle();
		handleUpdateUserPreference({ disableCaching: disableCaching });
	}
	
	async function handleUpdateUserPreference(data) {
		try {
			const response = await fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/users/${user.user.id}`, {
				method: 'PUT',
				headers: {
					'Strapi-Response-Format': 'v4',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${authToken}`
				},
				body: JSON.stringify(data),
			});
			const responseData = await response.json();		
			setUser({
				...user,
				user: responseData
			});
			console.error(Error);
		} finally {
			//setUserInfoUpdating(false);
		}
	}
	
	if(user.user) {
		return(
			<>
				<div className={`dropdown user-dropdown${visibleMenu === 'user' ? (' visible') : ('')}`} ref={ref}>
					<div className="trial-badge-wrapper mobile-only">
						<TrialBadge />
					</div>
					<ul>
						<li>
							<Link to="/account" onClick={handleDropdownToggle}>
								<div className="icon">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12 12C9.78125 12 8 10.2188 8 8C8 5.8125 9.78125 4 12 4C14.1875 4 16 5.8125 16 8C16 10.2188 14.1875 12 12 12ZM13.5625 13.5C16.5625 13.5 19 15.9375 19 18.9375C19 19.5312 18.5 20 17.9062 20H6.0625C5.46875 20 5 19.5312 5 18.9375C5 15.9375 7.40625 13.5 10.4062 13.5H13.5625Z" fill="#0B00D1"/>
									</svg>															
								</div>
								{t('userMenu.account')}
							</Link>
						</li>
					</ul>
					<ul>
						<li className="padded">
							<div className="icon">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6 19.5V5C6 4.46875 5.53125 4 5 4C4.4375 4 4 4.46875 4 5V19.5C4 19.7812 4.21875 20 4.5 20H5.5C5.75 20 6 19.7812 6 19.5ZM18.875 4C19.4688 4 20 4.375 20 4.96875V14.4062C20 14.7812 19.75 15.125 19.3125 15.2812C17.9688 15.8125 16.8438 16 15.8438 16C13.5312 16 11.9375 15.0312 9.625 15.0312C8.84375 15.0312 8 15.1562 7 15.4688V4.5C8.09375 4.15625 9 4.03125 9.78125 4.03125C11.9375 4.03125 13.0312 5.03125 14.9062 5.03125C15.7812 5.03125 16.8438 4.8125 18.25 4.15625C18.4688 4.0625 18.6562 4 18.875 4Z" fill="#0B00D1"/>
								</svg>						
							</div>						
							<div className="dropdown-options-wrapper">
								{t('navMenu.interfaceLanguage.interfaceLanguage')}
								<ToggleButtons
									items={toggleButtonLangs}
									value={localStorage.getItem('lang')}
									onClick={handleSwitchLanguage}
								/>
								{localStorage.getItem('lang') === 'is' && (								
									<div className="explanation">
										{t('navMenu.interfaceLanguage.courseMaterial')}
									</div>
								)}
							</div>
						</li>
						<li className="padded">
							<div className="icon">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4C16.4062 4 20 7.59375 20 12ZM12 6V18C15.3125 18 18 15.3125 18 12C18 8.6875 15.3125 6 12 6Z" fill="#0B00D1"/>
								</svg>							
							</div>						
							<div className="dropdown-options-wrapper">
								{t('navMenu.appearance.appearance')}
								<ToggleButtons
									items={toggleButtonAppearance}
									value={localStorage.getItem('appearance')}
									onClick={handleSwitchAppearance}
								/>
							</div>
						</li>
						{user.user.advancedMode && (
							<li className="padded">
								<div className="icon">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M18 6.5V8C18 9.40625 14.8438 10.5 11 10.5C7.125 10.5 4 9.40625 4 8V6.5C4 5.125 7.125 4 11 4C14.8438 4 18 5.125 18 6.5ZM16.2812 10.7188C16.9062 10.5 17.5312 10.1875 18 9.84375V13C18 14.4062 14.8438 15.5 11 15.5C7.125 15.5 4 14.4062 4 13V9.84375C4.4375 10.1875 5.0625 10.5 5.6875 10.7188C7.09375 11.2188 8.96875 11.5 11 11.5C13 11.5 14.875 11.2188 16.2812 10.7188ZM5.6875 15.7188C7.09375 16.2188 8.96875 16.5 11 16.5C13 16.5 14.875 16.2188 16.2812 15.7188C16.9062 15.5 17.5312 15.1875 18 14.8438V17.5C18 18.9062 14.8438 20 11 20C7.125 20 4 18.9062 4 17.5V14.8438C4.4375 15.1875 5.0625 15.5 5.6875 15.7188Z" fill="#0B00D1"/>
									</svg>														
								</div>						
								<div className="dropdown-options-wrapper">
									{t('navMenu.contentCaching.contentCaching')}
									<ToggleButtons
										items={toggleButtonCaching}
										value={user.user.disableCaching}
										onClick={handleSwitchCaching}
									/>
								</div>
							</li>
						)}						
					</ul>				
				</div>
			</>
		)
	}	 
	
}

function ExternalNav({
		mode
	}) {
	
	const path = useLocation().pathname;
	
	return(
		<nav className={`external${mode ? ` ${mode}` : ''}`}>
			<div className="left">
					<div className="logo">							
						<a href="/">
							<img src="/images/icelandic-lessons-logo.svg" alt="Icelandic with Max logo" width="36px" />
							Icelandic with Max
						</a>
					</div>
			</div>
			{mode!=="pre-launch" && (
				<div className="right">
					{path !== '/login' && (
						<Link to="/login" role="button" className="button secondary">Log in</Link>
					)}
					{path === '/login' ? (
						<Link to="/signup" role="button" className="button secondary mobile-hidden">Sign up</Link>
					) : (
						<Link to="/signup" role="button" className="button mobile-hidden">Sign up</Link>
					)}				
				</div>
			)}			
		</nav>
	)
	
}

function InternalNav() {
	
	const user = useAuthContext();
		
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const ref = useRef(null);
	
	useEffect(() => {
		if(dropdownVisible) {			
			document.body.classList.add('no-scroll');
		} else {			
			document.body.classList.remove('no-scroll');
		}				
	}, [dropdownVisible]);
		
	function handleDropdownToggle(menu) {
		if(dropdownVisible) {			
			setDropdownVisible(false);
		} else {			
			setDropdownVisible(menu);
		}		
	}
	
	useEffect(() => {		
		const handleClick = (event) => {
			if(ref.current && !ref.current.contains(event.target)) {
				setDropdownVisible(false);
			} else {
				if(event.target.classList.contains('menu-toggle')) {
					console.log('Menu button clicked');
				}
			}
		};
		
		document.addEventListener('click', handleClick);
		
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, [dropdownVisible]);
	
	return(
		<>
			<nav className="internal" ref={ref}>
				<div className="left">
					<button className={`menu-toggle icon${dropdownVisible === 'nav' ? (' active') : ('')}`} onClick={() => handleDropdownToggle('nav')}>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z" fill="#0B00D1"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z" fill="#0B00D1"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M3 18C3 17.4477 3.44772 17 4 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18Z" fill="#0B00D1"/>
						</svg>
					</button>						
					<Breadcrumbs 
						visibleMenu={dropdownVisible}
						setVisibleMenu={handleDropdownToggle}
						parent={ref}
						handleDropdownToggle={handleDropdownToggle}
					/>
				</div>
				<NavDropdown 
					visibleMenu={dropdownVisible}
					setVisibleMenu={handleDropdownToggle}
					parent={ref}
					handleDropdownToggle={handleDropdownToggle}
				/>				
				<>
					<div className="right">
						<div className="mobile-hidden">
							<TrialBadge />
						</div>
						<button className={`user-menu menu-toggle${dropdownVisible === 'user' ? (' active') : ('')}`} onClick={() => handleDropdownToggle('user')}>
							<div className="user-avatar">
								<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7 8C4.78125 8 3 6.21875 3 4C3 1.8125 4.78125 0 7 0C9.1875 0 11 1.8125 11 4C11 6.21875 9.1875 8 7 8ZM8.5625 9.5C11.5625 9.5 14 11.9375 14 14.9375C14 15.5312 13.5 16 12.9062 16H1.0625C0.46875 16 0 15.5312 0 14.9375C0 11.9375 2.40625 9.5 5.40625 9.5H8.5625Z" fill="#1C1E21"/>
								</svg>																
							</div>
							{user.user && (
								user.user.firstName				
							)}			
						</button>
					</div>
					<UserDropdown 
						visibleMenu={dropdownVisible}
						setVisibleMenu={handleDropdownToggle}
						parent={ref}
						handleDropdownToggle={handleDropdownToggle}
					/>
				</>	
			</nav>	
			<div className="dropdown-shader"></div>
		</>
	)
}

export default function Nav({
		mode
	}) {

	const path = useLocation().pathname;
	
	var externalPage = false;
	
	if(path==='/') {
		externalPage = true;
	} else if(path==='/login') {
		externalPage = true;
	} else if(path==='/signup') {
		externalPage = true;
	} else if(path==='/reset-password') {
		externalPage = true;
	} else if(/^\/blog.*$/.test(path)) {
		externalPage = true;
	}

	return (
		<>
			{externalPage ? (
				<ExternalNav mode={mode} />
			) : (			
				<InternalNav />
			)}
		</>
	)
}