import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export function Button({ 
		children,
		disabled,
		fullWidth,
		icon,
		iconPlacement,
		label,
		loading,
		onClick,
		role 
	}) {
		
	if(!iconPlacement) {
		iconPlacement = 'before';
	}
	
	var svg = null;
		
	switch(icon) {
		case 'previous':
			svg = (<><svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8 14.5C7.71875 14.5 7.46875 14.4062 7.28125 14.2188L1.28125 8.21875C0.875 7.84375 0.875 7.1875 1.28125 6.8125L7.28125 0.8125C7.65625 0.40625 8.3125 0.40625 8.6875 0.8125C9.09375 1.1875 9.09375 1.84375 8.6875 2.21875L3.40625 7.5L8.6875 12.8125C9.09375 13.1875 9.09375 13.8438 8.6875 14.2188C8.5 14.4062 8.25 14.5 8 14.5Z" fill="white"/>
				</svg></>)
			break;
		case 'next':
			svg = (<><svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M2 14.5C1.71875 14.5 1.46875 14.4062 1.28125 14.2188C0.875 13.8438 0.875 13.1875 1.28125 12.8125L6.5625 7.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L8.6875 6.8125C9.09375 7.1875 9.09375 7.84375 8.6875 8.21875L2.6875 14.2188C2.5 14.4062 2.25 14.5 2 14.5Z" fill="white"/>
				</svg></>)
			break;
		case 'download':
			svg = (<><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M17 13C17.5312 13 18 13.4688 18 14V17C18 17.5625 17.5312 18 17 18H3C2.4375 18 2 17.5625 2 17V14C2 13.4688 2.4375 13 3 13H7.15625L8.5625 14.4375C8.9375 14.8125 9.4375 15 10 15C10.5312 15 11.0312 14.8125 11.4062 14.4375L12.8125 13H17ZM15.5 16.25C15.9062 16.25 16.25 15.9375 16.25 15.5C16.25 15.0938 15.9062 14.75 15.5 14.75C15.0625 14.75 14.75 15.0938 14.75 15.5C14.75 15.9375 15.0625 16.25 15.5 16.25ZM9.28125 13.7188L5.28125 9.71875C4.875 9.34375 4.875 8.6875 5.28125 8.3125C5.65625 7.90625 6.3125 7.90625 6.6875 8.3125L9 10.5938V3C9 2.46875 9.4375 2 10 2C10.5312 2 11 2.46875 11 3V10.5938L13.2812 8.3125C13.6562 7.90625 14.3125 7.90625 14.6875 8.3125C15.0938 8.6875 15.0938 9.34375 14.6875 9.71875L10.6875 13.7188C10.5 13.9062 10.25 14 10 14C9.71875 14 9.46875 13.9062 9.28125 13.7188Z" fill="#0B00D1"/>
				</svg></>)
			break;
		case 'logOut':
			svg = (<><svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M3.5 14.5C1.8125 14.5 0.5 13.1562 0.5 11.5V3.5C0.5 1.84375 1.8125 0.5 3.5 0.5H5.5C6.03125 0.5 6.5 0.96875 6.5 1.5C6.5 2.0625 6.03125 2.5 5.5 2.5H3.5C2.9375 2.5 2.5 2.96875 2.5 3.5V11.5C2.5 12.0625 2.9375 12.5 3.5 12.5H5.5C6.03125 12.5 6.5 12.9688 6.5 13.5C6.5 14.0625 6.03125 14.5 5.5 14.5H3.5ZM16.25 6.96875C16.5625 7.25 16.5625 7.78125 16.25 8.03125L11.75 12.2812C11.5312 12.5 11.2188 12.5312 10.9375 12.4375C10.6562 12.3125 10.5 12.0312 10.5 11.75V9.5H6.5C5.9375 9.5 5.5 9.0625 5.5 8.5V6.5C5.5 5.96875 5.9375 5.5 6.5 5.5H10.5V3.25C10.5 2.96875 10.6562 2.6875 10.9375 2.5625C11.2188 2.46875 11.5312 2.5 11.75 2.71875L16.25 6.96875Z" fill="white"/>
				</svg></>)
			break;
		case 'save':
			svg = (<><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.0312 4.0625C14.2812 4.3125 14.5 4.78125 14.5 5.125V13C14.5 14.125 13.5938 15 12.5 15H2.5C1.375 15 0.5 14.125 0.5 13V3C0.5 1.90625 1.375 1 2.5 1H10.375C10.7188 1 11.1875 1.21875 11.4062 1.4375L14.0312 4.0625ZM7.5 13C8.59375 13 9.5 12.125 9.5 11C9.5 9.90625 8.59375 9 7.5 9C6.375 9 5.5 9.90625 5.5 11C5.5 12.125 6.375 13 7.5 13ZM10.5 6.5V3.5C10.5 3.25 10.25 3 10 3H3C2.71875 3 2.5 3.25 2.5 3.5V6.5C2.5 6.78125 2.71875 7 3 7H10C10.25 7 10.5 6.78125 10.5 6.5Z" fill="#1C1E21"/>
				</svg></>)
			break;
		case 'delete':
			svg = (<><svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M4.21875 1.0625C4.375 0.71875 4.71875 0.5 5.09375 0.5H8.875C9.25 0.5 9.59375 0.71875 9.75 1.0625L10 1.5H13C13.5312 1.5 14 1.96875 14 2.5C14 3.0625 13.5312 3.5 13 3.5H1C0.4375 3.5 0 3.0625 0 2.5C0 1.96875 0.4375 1.5 1 1.5H4L4.21875 1.0625ZM12.3125 15.0938C12.2812 15.9062 11.625 16.5 10.8125 16.5H3.15625C2.34375 16.5 1.6875 15.9062 1.65625 15.0938L0.96875 4.5H13L12.3125 15.0938Z" fill="#1C1E21"/>
				</svg></>)
			break;
		case 'options':
			svg = (<><svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M3.75 2C3.75 2.96875 2.9375 3.75 2 3.75C1.03125 3.75 0.25 2.96875 0.25 2C0.25 1.0625 1.03125 0.25 2 0.25C2.9375 0.25 3.75 1.0625 3.75 2ZM8.75 2C8.75 2.96875 7.9375 3.75 7 3.75C6.03125 3.75 5.25 2.96875 5.25 2C5.25 1.0625 6.03125 0.25 7 0.25C7.9375 0.25 8.75 1.0625 8.75 2ZM10.25 2C10.25 1.0625 11.0312 0.25 12 0.25C12.9375 0.25 13.75 1.0625 13.75 2C13.75 2.96875 12.9375 3.75 12 3.75C11.0312 3.75 10.25 2.96875 10.25 2Z" fill="#0B00D1"/>
				</svg></>)
			break;
		case 'close':
			svg = (<><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12.3594 10.6406C12.8672 11.1094 12.8672 11.9297 12.3594 12.3984C12.125 12.6328 11.8125 12.75 11.5 12.75C11.1484 12.75 10.8359 12.6328 10.6016 12.3984L6.5 8.29688L2.35938 12.3984C2.125 12.6328 1.8125 12.75 1.5 12.75C1.14844 12.75 0.835938 12.6328 0.601562 12.3984C0.09375 11.9297 0.09375 11.1094 0.601562 10.6406L4.70312 6.5L0.601562 2.39844C0.09375 1.92969 0.09375 1.10938 0.601562 0.640625C1.07031 0.132812 1.89062 0.132812 2.35938 0.640625L6.5 4.74219L10.6016 0.640625C11.0703 0.132812 11.8906 0.132812 12.3594 0.640625C12.8672 1.10938 12.8672 1.92969 12.3594 2.39844L8.25781 6.53906L12.3594 10.6406Z" fill="#1C1E21"/>
				</svg></>)
			break;
		case 'external':
			svg = (<><svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8 1.5C8 0.96875 8.4375 0.5 9 0.5H12.9688C13.125 0.5 13.25 0.53125 13.375 0.59375C13.4688 0.625 13.5938 0.71875 13.6875 0.8125C13.875 1 13.9688 1.25 14 1.5V5.5C14 6.0625 13.5312 6.5 13 6.5C12.4375 6.5 12 6.0625 12 5.5V3.9375L6.6875 9.21875C6.3125 9.625 5.65625 9.625 5.28125 9.21875C4.875 8.84375 4.875 8.1875 5.28125 7.8125L10.5625 2.5H9C8.4375 2.5 8 2.0625 8 1.5ZM0 3.5C0 2.40625 0.875 1.5 2 1.5H5C5.53125 1.5 6 1.96875 6 2.5C6 3.0625 5.53125 3.5 5 3.5H2V12.5H11V9.5C11 8.96875 11.4375 8.5 12 8.5C12.5312 8.5 13 8.96875 13 9.5V12.5C13 13.625 12.0938 14.5 11 14.5H2C0.875 14.5 0 13.625 0 12.5V3.5Z" fill="#1C1E21"/>
				</svg></>)
			break;
	}
	
	return(
		<button className={`${role}${fullWidth ? (' full-width') : ('')}`} onClick={onClick} disabled={disabled}>
			{loading ? (
				<Spinner size="small" color="black" />
			) : (
				<>
				{iconPlacement === 'before' && (			
					<>{svg}</>
				)}
				{label}
				{children}
				{iconPlacement === 'after' && (			
					<>{svg}</>
				)}
				</>
			)}		
		</button>
	)
	
}

export function SpecialCharsButton({ onClick }) {
	return(
		<button className="button specialchars" onClick={onClick} title="Toggle special characters">
			<svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.43204 0.968118L4.41604 3.70412L3.18404 3.11212L4.70404 0.120117L6.43204 0.968118ZM3.24804 13.1921C1.55204 13.1921 0.592041 12.2161 0.592041 10.7281C0.592041 9.01612 1.88804 8.16812 4.16004 8.16812H5.58404V7.56012C5.58404 6.63212 5.12004 6.08812 4.03204 6.08812C3.07204 6.08812 2.52804 6.56812 2.12804 7.14412L0.912041 6.05612C1.52004 5.09612 2.49604 4.45612 4.17604 4.45612C6.43204 4.45612 7.63204 5.52812 7.63204 7.43212V11.3681H8.46404V13.0001H7.32804C6.44804 13.0001 5.90404 12.4241 5.77604 11.5601H5.68004C5.40804 12.6321 4.49604 13.1921 3.24804 13.1921ZM3.93604 11.7041C4.84804 11.7041 5.58404 11.2881 5.58404 10.5041V9.40012H4.27204C3.20004 9.40012 2.67204 9.76812 2.67204 10.4241V10.6961C2.67204 11.3681 3.13604 11.7041 3.93604 11.7041Z" fill="#0B00D1"/>
			<path d="M17.656 10.9519L18.376 11.8159L17.192 12.5839C18.664 14.1039 19.64 16.1359 19.64 18.5839C19.64 21.7519 18.04 23.1919 15.704 23.1919C13.192 23.1919 11.688 21.5599 11.688 18.9839C11.688 16.5839 12.984 14.9839 15 14.9839C16.136 14.9839 16.92 15.5919 17.368 16.5519L17.464 16.5039C17.208 15.4319 16.52 14.4399 15.608 13.5439L14.232 14.4079L13.496 13.5279L14.744 12.7759C13.992 12.1519 13.16 11.6239 12.328 11.1599H15.448C15.736 11.3359 16.024 11.5599 16.312 11.7999L17.656 10.9519ZM15.672 21.6239C16.792 21.6239 17.512 20.8559 17.512 19.5599V18.6319C17.512 17.3359 16.792 16.5679 15.672 16.5679C14.536 16.5679 13.816 17.3359 13.816 18.6319V19.5599C13.816 20.8559 14.536 21.6239 15.672 21.6239Z" fill="#0B00D1"/>
			</svg>		
		</button>
	)	
}


export function ReloadButton({ title, onClick }) {
	return(
		<button className="reload" title={title} onClick={onClick}>
			<svg width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M40.875 0.09375C42.1875 0.09375 43.5 1.03125 43.5 2.625V16.3125C43.5 17.25 42.6562 18.0938 41.625 18.0938H28.0312C26.4375 18.0938 25.5 16.7812 25.5 15.5625C25.5 14.9062 25.6875 14.25 26.25 13.7812L30.4688 9.46875C27.8438 7.3125 24.4688 6.09375 20.9062 6.09375C12.6562 6.09375 6 12.8438 6 21.0938C6 29.3438 12.6562 36 20.9062 36C27.8438 36 29.3438 32.4375 31.7812 32.4375C33.4688 32.4375 34.6875 33.8438 34.6875 35.4375C34.6875 38.7188 27.0938 42 20.9062 42C9.375 42 0 32.625 0 21.0938C0 9.46875 9.375 0.09375 21 0.09375C26.0625 0.09375 30.9375 1.96875 34.6875 5.25L39.1875 0.84375C39.6562 0.28125 40.3125 0.09375 40.875 0.09375Z" fill="#FD4802"/>
			</svg>							
		</button>
	)
}

export function AudioButton({src, type}) {
	
	const audio = useRef();
	const [loading, setLoading] = useState(true);
	const [disabled, setDisabled] = useState('disabled');
	const [play, setPlay] = useState({
		style: null,
	});
	
	function handleLoadedMetadata() {
		setDisabled(false);
		setLoading(false);
	}
	
	function handlePlayAudio() {
		audio.current.play();
		setPlay({
			style: 'playing'
		});
	}
	
	function handleAudioEnded() {
		setPlay({
			style: null
		});
	}
		
	return(
		<span className="audio-button-wrapper">
			<button 
				className={`audio ${play.style}`}
				disabled={disabled}
				title="Play audio"
				onClick={handlePlayAudio}
				>
				<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15.2344 5.64844C16.043 6.31641 16.5 7.19531 16.5 8.25C16.5 9.26953 16.043 10.2188 15.2344 10.8516C15.0938 10.957 14.8828 11.0273 14.707 11.0273C14.4609 11.0273 14.2148 10.9219 14.0391 10.7109C13.7578 10.3594 13.793 9.83203 14.1797 9.51562C14.5664 9.19922 14.8125 8.74219 14.8125 8.25C14.8125 7.75781 14.5664 7.30078 14.1797 6.98438C13.793 6.66797 13.7578 6.14062 14.0391 5.78906C14.3555 5.4375 14.8828 5.36719 15.2344 5.64844ZM17.3789 3.08203C18.9609 4.34766 19.875 6.21094 19.875 8.25C19.875 10.2539 18.9609 12.1523 17.3789 13.418C17.2031 13.5586 17.0273 13.6289 16.8164 13.6289C16.5703 13.6289 16.3594 13.5234 16.1836 13.3125C15.8672 12.9609 15.9375 12.4336 16.2891 12.1172C17.4844 11.1328 18.1875 9.69141 18.1875 8.25C18.1875 6.77344 17.4844 5.33203 16.2891 4.38281C15.9375 4.06641 15.8672 3.53906 16.1836 3.1875C16.4648 2.83594 16.9922 2.76562 17.3789 3.08203ZM19.5234 0.445312C21.8789 2.37891 23.25 5.15625 23.25 8.25C23.25 11.3086 21.8789 14.1562 19.5234 16.0898C19.3477 16.2305 19.1719 16.2656 18.9961 16.2656C18.75 16.2656 18.5039 16.1602 18.3281 15.9492C18.0469 15.5977 18.082 15.0352 18.4336 14.7539C20.4023 13.1367 21.5625 10.7461 21.5625 8.25C21.5625 5.71875 20.4023 3.36328 18.4336 1.74609C18.082 1.46484 18.0469 0.902344 18.3281 0.550781C18.6445 0.199219 19.1719 0.164062 19.5234 0.445312ZM11.332 0.480469C11.7188 0.691406 12 1.07812 12 1.53516V15C12 15.457 11.7188 15.8438 11.332 16.0195C11.1562 16.0898 11.0156 16.125 10.875 16.125C10.5938 16.125 10.3125 16.0195 10.1016 15.8438L5.35547 11.625H2.4375C1.48828 11.625 0.75 10.8867 0.75 9.9375V6.59766C0.75 5.64844 1.48828 4.91016 2.4375 4.91016H5.35547L10.1016 0.691406C10.4531 0.375 10.9102 0.304688 11.332 0.480469Z" fill="#0B00D1"/>
				</svg>			
				<audio ref={audio} onEnded={handleAudioEnded} onLoadedMetadata={handleLoadedMetadata}>
					<source src={src} type={type} />
					Your browser does not support the audio element.
				</audio>
			</button>
		</span>
	);
	
}

export function ToggleButtons({
		items,
		value,
		onClick
	}) {
		
	const [currentValue, setCurrentValue] = useState(value);
	
	function handleValueChange(value) {
		setCurrentValue(value);
		onClick(value);
	}
		
	return(
		<div className="toggle-buttons-wrapper">
			<input type="hidden" value={currentValue} />
			{items.map((thisItem, i) =>
				<button 
					className={`toggle${currentValue === thisItem.value ? (' selected') : ('')}`}
					onClick={() => handleValueChange(thisItem.value)}
					key={i}
				>
					{thisItem.title}
				</button>
			)}
		</div>
	)
	
}

export function ProgressDonut({
		animate,
		value,
		label,
		size,
		status
	}) {
		
	const { t } = useTranslation();
	
	const [strokePercentage, setStrokePercentage] = useState(0);
	
	if(!status && value === 100) {
		status = "complete";
		label = t('dashboard.complete');
	}
	
	if(!value && !status) {
		status = "unstarted";
	}
	
	var box = 24;
	var c = 12;
	var r = 8;
	var strokeWidth = 4;
	
	if(size==="extra-large") {
		var box = box*3.25;
		var c = c*3.25;
		var r = r*4;
		var strokeWidth = strokeWidth*3;
	}
			
	useEffect(() => {
		if(animate) {
			const id = setInterval(() => {
				if(strokePercentage<value) {
					setStrokePercentage(value);
				}				
			}, 500);
			return () => {
				clearInterval(id);
			};
		} else {
			setStrokePercentage(value);
		}
	}, [strokePercentage]);
	
	return(
		<div className={`progress-donut-wrapper${status ? ` ${status}` : ''}${size ? ` ${size}` : ''}`}>
			{(status === "complete" || value === 100) ? (
				<img src="/images/icon-success.svg" width={box} height={box} alt="Green tick" />
			) : ( 
				<svg focusable="false" xmlns="http://www.w3.org/2000/svg" width={box} viewBox={`0 0 ${box} ${box}`}>
					<circle 
						className="bar"
						cx={c}
						cy={c}
						r={r}
						pathLength="100"
						transform={`rotate(-90 ${c} ${c})`}
						strokeDasharray={`${strokePercentage} 100`}
						strokeWidth={strokeWidth}
					/>
					<circle 
						className="background"
						cx={c}
						cy={c}
						r={r}
						pathLength="100"
						transform="rotate(-90 ${c} ${c})"
						strokeWidth={strokeWidth}
					/>
				</svg>	
			)}					
			{label && (
				<div className="progress-donut-label">{label}</div>
			)}
		</div>
	)
	
}

export function Spinner({
	color,
	size
}) {
	
	var box = 64;
	var c = 32;
	var r = 16;
	var strokeWidth = 6;
	
	if(size==="small") {
		var box = 20;
		var c = 10;
		var r = 8;
		var strokeWidth = 3;
	}

	return(
		<div className={`spinner${color ? ` ${color}` : ''}`}>	
			<svg focusable="false" xmlns="http://www.w3.org/2000/svg" width={box} height={box} viewBox={`0 0 ${box} ${box}`}>
				<circle 
					className="spinner-bar"
					cx={c}
					cy={c}
					r={r}
					pathLength="100" 
					strokeWidth={strokeWidth}
				/>
				<circle 
					className="spinner-background"
					cx={c}
					cy={c}
					r={r}
					pathLength="100"
					transform={`rotate(-90 ${c} ${c})`} 
					strokeWidth={strokeWidth}
				/>
			</svg>	
		</div>
	)

}

export function LoadError({ children }) {
	
	const { t } = useTranslation();
	
	return(
		<div className="error-wrapper">
			{children ? (
				<>{children}</>
			) : (
				<>
					<h2>{t('errors.pageLoadError')}</h2>
					<ReloadButton title="Try again" onClick={() => window.location.reload()} />
				</>
			)}
		</div>
	)
	
}